import React from "react";
import { Flex, Box,} from "@chakra-ui/react";
import Hero from "../components/Common/Hero";
import ProjectTiles from "../components/Tiles/ProjectTiles";
const ProjectsPage = () => {
    return (
            <Flex>
                <Box>
                    <ProjectTiles />
                </Box>
                <Box flex="1">
                    <Hero />
                </Box>
            </Flex>
    );
};

export default ProjectsPage;
