import React from 'react';
import './styles/hero.css';

const Hero = () => {
    return (
        <div className="heroContainer">
            <iframe
                className="iframeBackground"
                src="/stars.html"
                title="Starry Background"
            ></iframe>
        </div>
    );
};

export default Hero;
