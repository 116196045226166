import React from "react";
import { Flex, Box} from "@chakra-ui/react";
import Hero from "../components/Common/Hero";
import AboutTiles from "../components/Tiles/AboutTiles";

const AboutPage = () => {
    return (
        <Flex>
            <Box>
                <AboutTiles />
            </Box>
            <Box flex="1">
                <Hero />
            </Box>
        </Flex>
    );
};

export default AboutPage;
