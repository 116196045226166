import  React, { useEffect, useState } from 'react';
import { Box, Flex, IconButton, Image, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai';
import projects from './Content/ProjectContent';
import './styles/project-tiles.css';

const MotionBox = motion(Box);

export default function ProjectTiles() {
    const [currentProject, setCurrentProject] = useState(0);

    useEffect(() => {
        // Change content every 15 seconds
        const interval = setInterval(() => {
            setCurrentProject((prevProject) => (prevProject + 1) % projects.length);
        }, 15000);

        return () => clearInterval(interval);
    }, []);

    const nextProject = () => {
        setCurrentProject((prev) => (prev + 1) % projects.length);
    };

    const prevProject = () => {
        setCurrentProject((prev) => (prev - 1 + projects.length) % projects.length);
    };

    return (
        <Flex className="flex-parent-container">
            {/* Image Content */}
            <Flex className="flex-image-container">
                <IconButton aria-label="Previous project" icon={<AiFillCaretLeft />} onClick={prevProject} className="icon-button" />
                <MotionBox className="animation-enter" key={projects[currentProject].image}>
                    <Image src={projects[currentProject].image} alt={projects[currentProject].title} className="project-image" />
                </MotionBox>
                <IconButton aria-label="Next project" icon={<AiFillCaretRight />} onClick={nextProject} className="icon-button" />
            </Flex>

            {/* Text Content */}
            <Box className="text-box">
                <Heading className="heading-xl">{projects[currentProject].title}</Heading>
                <Heading className="heading-xl">{projects[currentProject].description}</Heading>
                <div className="details-content heading-xl"
                     dangerouslySetInnerHTML={{__html: projects[currentProject].details}}/>
            </Box>
        </Flex>
    );
}
