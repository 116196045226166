import React, {useEffect, useState} from 'react';
import {Box, Flex, IconButton, Image, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { AiFillCaretRight } from "react-icons/ai";
import { AiFillCaretLeft } from "react-icons/ai";
import AboutData from './Content/AboutContent';
import './styles/about-tiles.css'

const MotionBox = motion(Box);

export default function AboutTiles() {
    const [currentData, setCurrentData] = useState(0);

    useEffect(() => {
        // Change content every 12 seconds
        const interval = setInterval(() => {
            setCurrentData((prevProject) => (prevProject + 1) % AboutData.length);
        }, 12000);

        return () => clearInterval(interval);
    }, []);

    const nextAboutData = () => {
        setCurrentData((prev) => (prev + 1) % AboutData.length);
    };

    const prevAboutData = () => {
        setCurrentData((prev) => (prev - 1 + AboutData.length) % AboutData.length);
    };

    return (
        <Flex className="flex-parent-container">

            {/* Image Content*/}
            <Flex className="flex-image-container">
                <IconButton
                    aria-label="Previous project"
                    icon={<AiFillCaretLeft />}
                    onClick={prevAboutData}
                    className="icon-button"
                />
                <MotionBox
                    className="animation-enter"
                    key={AboutData[currentData].image}
                >
                    <Image
                        src={AboutData[currentData].image}
                        alt={AboutData[currentData].title}
                        className="project-image"
                    />
                </MotionBox>
                <IconButton
                    aria-label="Next project"
                    icon={<AiFillCaretRight />}
                    onClick={nextAboutData}
                    className="icon-button"
                />
            </Flex>

            {/* Text Content */}
            <Box className="text-box">
                <Heading className="heading-xl">
                    {AboutData[currentData].title}
                </Heading>
                <Heading className="heading-xl">
                    {AboutData[currentData].description}
                </Heading>
                <Heading className="heading-xl">
                    {AboutData[currentData].details}
                </Heading>
            </Box>

        </Flex>
    );
}