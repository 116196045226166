import React from 'react';

const Contact = () => {
    return (
        <div className="contact">
            <p>If you have any questions or would like to get in touch, feel free to contact me through the following channels:</p>

            <ul>
                <li>Email: <a href="mailto:abhandar@oswego.edu">abhandar@oswego.edu</a></li>
                <li>LinkedIn: <a href="https://www.linkedin.com/in/ayushabhandari" target="_blank" rel="noopener noreferrer">ayushabhandari</a></li>
                <li>Appointment: <a href="https://abhandar.youcanbook.me/" target="_blank" rel="noopener noreferrer">Schedule a Meeting</a></li>
            </ul>
        </div>
    );
}

export default Contact;
