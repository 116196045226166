import React, {useEffect, useState} from 'react';
import {Box, Flex, Image, Heading, IconButton} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { AiFillCaretRight } from "react-icons/ai";
import { AiFillCaretLeft } from "react-icons/ai";
import HomeData from './Content/HomeData';
import './styles/home-tiles.css'

const MotionBox = motion(Box);

export default function HomeTiles() {
    const [currentData, setCurrentData] = useState(0);

    useEffect(() => {
        // Change content every 10 seconds
        const interval = setInterval(() => {
            setCurrentData((prevProject) => (prevProject + 1) % HomeData.length);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const nextHomeData = () => {
        setCurrentData((prev) => (prev + 1) % HomeData.length);
    };

    const prevHomeData = () => {
        setCurrentData((prev) => (prev - 1 + HomeData.length) % HomeData.length);
    };

    return (
        <Flex className="flex-parent-container">

            {/* Image Content*/}
            <Flex className="flex-image-container">
                <IconButton
                aria-label="Previous Data"
                icon={<AiFillCaretLeft />}
                onClick={prevHomeData}
                className="icon-button"
                />
                <MotionBox
                    className="animation-enter"
                    key={HomeData[currentData].image}
                >
                    <Image
                        src={HomeData[currentData].image}
                        alt={HomeData[currentData].firstline}
                        className="project-image"
                    />
                </MotionBox>
                <IconButton
                    aria-label="Next Data"
                    icon={<AiFillCaretRight />}
                    onClick={nextHomeData}
                    className="icon-button"
                />
            </Flex>

            {/* Text Content */}
            <Box className="text-box">
                <Heading className="heading-xl">
                    {HomeData[currentData].firstline}
                </Heading>
                <Heading className="heading-xl">
                    {HomeData[currentData].secondline}
                </Heading>
                <Heading className="heading-xl">
                    {HomeData[currentData].thirdline}
                </Heading>
            </Box>

        </Flex>
    );
}