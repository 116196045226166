const HomeData = [
    {
        image: "images/Home/ayusha.png",
        firstline: "Hello! I'm Ayusha Bhandari",
        secondline: "A passionate UX Researcher and Designer",
        thirdline: "I started this project to get familiar with web development methodologies, bear with me I have no prior CS knowledge and going through this using Youtube",
    },
    {
        image: "images/Home/ayusha.png",
        firstline: "I implemented this portfolio to highlight the work I have done during my internships and research work.",
        secondline: "Feel free to explore the projects page to have a look and you can also find methods to contact me through the contacts page.",
        thirdline: `I'm planning to make this way better as my knowledge gets better.`,
    },
    {
        image: "images/Home/ayusha.png",
        firstline: "Also that AI generated image of me is from Fotor AI, try it yourselves.",
        secondline: "I used the Vintage Comics filter",
        thirdline: 'The other filters they have are also very "interesting"',
    },
];
export default HomeData;
