const AboutData = [
    {
        image: 'images/About/photo.png',
        title: 'Hello! I’m Ayusha Bhandari, a passionate UX Researcher and Designer from Nepal,',
        description: 'Currently enriching my knowledge in Psychology and Human-Computer Interaction, backed by a minor in Cognitive Science.',
        details: 'I’m in the pursuit of a comprehensive 5-year Masters program (which I plan to finish in 3 years), blending my ardor for technology and design to unravel the myriad facets of user interactions and experiences.',
    },
    {
        image: 'images/About/tools.png',
        title: 'What I offer',
        description: 'My journey spans various realms, from being a proactive Research Assistant at SUNY Oswego\'s renowned labs, where I delve into cognitive dynamics and social cognition, to contributing to technology design and enhancements.',
        details: 'My forte lies in my unique approach, integrating dynamical systems theories with conventional psychological principles to understand human behaviors and motivations deeply.',
    },
    {
        image: 'images/About/Cognition.webp',
        title: 'Experience and Approach',
        description: 'In my pursuit, I have embraced varied roles, probing into the intricacies of cognitive dynamics and social cognition.',
        details: 'My approach is a harmonious blend of dynamical systems theories and classical psychological principles to dissect and understand the myriad facets of human behavior and motivations.',
    },
    {
        image: 'images/About/1.User Centered Design.jpg',
        title: 'Multifaceted Expertise',
        description: 'User-Centric Design & Research: Focusing on creating solutions and experiences that are intuitive, engaging, and user-friendly, driven by in-depth user research and analysis.',
        details: 'International Perspective: My experiences as an international student have endowed me with a global outlook, allowing me to design with a diverse and inclusive lens, catering to a wide range of user needs and preferences.',
    },
];
export default AboutData;
