import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import Hero from "../components/Common/Hero";
import HomeTiles from "../components/Tiles/HomeTiles";
const HomePage = () => {
    return (
        <Flex>
            <Box>
                <HomeTiles/>
            </Box>
            <Box flex="1">
                <Hero />
            </Box>
        </Flex>
    );
};

export default HomePage;
