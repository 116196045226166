import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import './styles/footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <Box className="footerContainer">
            <iframe
                src="/stars.html"
                className="iframeBackground"
                title="Starry Background"
            />

            <Text className="footerText" as="footer">
                &copy; {currentYear} Ayusha Bhandari. All rights reserved.
            </Text>
        </Box>
    );
};

export default Footer;
