const projects = [
    {
        image: 'images/Projects/ISSS.png',
        title: 'UX Designer Intern, ISSS Oswego',
        description: 'Website Redesign & Management',
        details: 'Aiming to transform the ISSS website into an intuitive, user-centric platform by addressing usability concerns and enhancing data management for both international students and the administrative staff. For more information, visit <a href="https://www.oswego.edu/international/international-student-and-scholar-services-1" target="_blank">ISSS SUNY Oswego</a>.',
    },
    {
        image: 'images/Projects/Capstone.png',
        title: 'Researcher, Cognitive Dynamics Lab',
        description: 'Decision-Making & Attractiveness Project',
        details: 'Examine the interplay between perceived attractiveness and decision-making processes in the context of the Monty Hall dilemma. For more information, visit <a href="https://oswego.az1.qualtrics.com/jfe/preview/previewId/770c536e-8901-455e-a234-8333ec011598/SV_39hWwxVzOgwADVc?Q_CHL=preview&Q_SurveyVersionID=current" target="_blank">Project Site</a>.',
    },
    {
        image: 'images/Projects/Perception.png',
        title: 'Research Assistant, Social Cognition and Motivation Lab',
        description: 'Personality and Visual Memory Study:',
        details: 'To explore the influence of personality traits and temperature sensations on pathogen-avoidance motivations. For more information, visit <a href="https://ww1.oswego.edu/psychology/research-labs" target="_blank">Social Cognition and Motivation Lab</a>.',
    },
    {
        image: 'images/Projects/GENAI.png',
        title: 'Generative Art: Neural Networks vs. Human Artists',
        description: 'Examine the emergence and implications of AI-generated art in comparison to traditional human-created artworks.',
        details: 'Examine the emergence and implications of AI-generated art in comparison to traditional human-created artworks. For more information, visit <a href="https://docs.google.com/document/d/1iIUryOXEXuqz6MRP0oQCFg8D3kW6wPPegYyNDCpvbmM/edit?usp=sharing" target="_blank">Paper link</a>.',
    },
    {
        image: 'images/Projects/Weltory.png',
        title: 'Research Assistant, Trauma Studies Lab',
        description: 'User Adherence & Experience Enhancement for Welltory iOS App',
        details: 'Assess and improve the Welltory iOS app’s user experience, aiming to optimize adherence and engagement. For more information, visit <a href="https://welltory.com/" target="_blank">Welltory</a>.',
    },
];
export default projects;
