import React, { useState } from 'react';
import {Flex, Text, Button, Image, Box} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import Contact from './Contact';
import './styles/header.css';

const Header = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="headerContainer">
            <iframe
                className="iframeBackground"
                src="/stars.html"
                title="Starry Background"
            />
            <Flex as="nav" className="nav">
                <Flex className="logoContainer">
                    <Link to="/">
                        <Image src="/images/Common/logo.webp" alt="Logo" className="logoImage" mr={10} />
                    </Link>
                    <Text className="logoText">
                        Ayusha Bhandari <br /> UX Researcher | Cognitive Scientist
                    </Text>
                </Flex>
                <Box>
                    <Link to="/">
                        <Button as="a" className="buttonHoverStyle">Home</Button>
                    </Link>
                    <Link to="/about">
                        <Button as="a" className="buttonHoverStyle">About</Button>
                    </Link>
                    <Link to="http://cs.oswego.edu/~abhandar/Resume.pdf">
                        <Button as="a" className="buttonHoverStyle">Resume</Button>
                    </Link>
                    <Link to="/projects">
                        <Button as="a" className="buttonHoverStyle">Projects</Button>
                    </Link>
                    <Button as="a" className="buttonHoverStyle" onClick={showModal}>Contact</Button>
                </Box>
                <Modal
                    title="Contact Me"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Contact />
                </Modal>
            </Flex>
        </div>
    );
};

export default Header;
